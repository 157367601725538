// alert('huhu Cookieconsent!');

(function($) {

    var inputs, modal;

   var init = function () {
       // let me = this;

       $( document ).ready(function() {
           inputs = $('.cookie--consent__checkbox-check');
           modal = $('.cookie--consent');
           let cookie = getCookie();

           if(modal && modal.length) {
               if((!cookie) && (!isPrivacyPage())) {
                   open();
               }
               start();
               // loadAnalytics();
               // loadTagManager();
           }
       });
   }

   var isPrivacyPage = function () {
       var pr = 'datenschutzerkl';

       if(window.location.pathname && window.location.pathname.indexOf(pr) > 0) {
           return true;
       }

       return false;
   }

    var getCookie = function() {
        return document.cookie.match(/cookieAllow=([^;]+)/);
    }


    var saveCookies = function () {
        let val = '~req~';

        for( let i=0; i < inputs.length; i++) {
            val += inputs[i].checked ? inputs[i].value : "~no" + inputs[i].value.slice(1);
        }

        let domain = document.domain;

        if(domain.indexOf('www') >= 0) {
            domain = document.domain,document.domain.split('.').slice(1).join('.');
        }

        document.cookie = "cookieAllow=" + val + "; max-age=15552000; path=/; domain=" + domain;
    }

    var checkAll = function () {
        for( let i=0; i < inputs.length; i++) {
            inputs[i].checked = true;
        }
    }

    var close = function () {
        modal.removeClass('is-active');
    }

    var open = function() {
        modal.addClass('is-active');
    }

    var start = function () {
        // let me = this;
        let confirm = $('#cookie--consent_confirm');
        let confirmAll = $('#cookie--consent_confirmAll');
        let openButton = $('#cookie--consent_open');
        let saveOnChange = false;

        if(confirmAll && confirmAll.length) {
            let cookie = (getCookie() || ['', ''])[1];

            for (let i = 0; i < inputs.length; i++) {
                let ic = inputs[i];
                ic.checked = cookie.indexOf(ic.value) >= 0;
                saveOnChange && ic.addEventListener("change", function () {
                    saveCookies()
                })
            }
        }

        confirm.on('click',function() {
            saveCookies();
            close();
            setTimeout(function() {
                window.location.reload();
            }, 250);
        });

        confirmAll.on('click',function() {
            checkAll();
            saveCookies();
            close();
            setTimeout(function() {
                window.location.reload();
            }, 250);
        });

        openButton.on('click',function(e) {
            e.preventDefault();
            open();
        });

    }

    var allowTagging = function () {
        let cookie = getCookie();

        if(cookie && typeof cookie === 'object' && cookie[0].indexOf('~stat~') > 0 && (!isPrivacyPage())) {
            return true;
        }

        return false;
    }

    init();

})(jQuery);


//
// class Cookieconsent {
//
// 	constructor() {
//     let me = this;
//
// 		$( document ).ready(function() {
//       me.inputs = $('.cookie--consent__checkbox-check');
//       me.modal = $('.cookie--consent');
//       let cookie = me.getCookie();
//       let privacy_page = $('.node-76');
//
// 		  if(me.modal && me.modal.length) {
// 		    if((!cookie) && (privacy_page.length === 0)) {
//           me.open();
//         }
//         me.start();
// 		    me.loadAnalytics();
// 		    me.loadTagManager();
//       }
// 		});
// 	}
//
// 	getCookie() {
// 	  return document.cookie.match(/cookieAllow=([^;]+)/);
//   }
//
//   saveCookies() {
//     let val = '~req~';
//     for( let i=0; i < this.inputs.length; i++) {
//       val += this.inputs[i].checked ? this.inputs[i].value : "~no" + this.inputs[i].value.slice(1);
//     }
//
//     let domain = document.domain;
//
//     if(domain.indexOf('www') >= 0) {
//       domain = document.domain,document.domain.split('.').slice(1).join('.');
//     }
//
//     // if(typeof console === 'object') { console.log('cookie',domain); }
//     document.cookie = "cookieAllow=" + val + "; max-age=15552000; path=/; domain=" + domain;
//   }
//
//   checkAll() {
//     for( let i=0; i < this.inputs.length; i++) {
//       this.inputs[i].checked = true;
//     }
//   }
//
//   close() {
//     this.modal.removeClass('is-active');
//   }
//
//   open() {
//     this.modal.addClass('is-active');
//   }
//
//   start() {
//     let me = this;
// 	  let confirm = $('#cookie--consent_confirm');
// 	  let confirmAll = $('#cookie--consent_confirmAll');
// 	  let openButton = $('#cookie--consent_open');
//     let saveOnChange = false;
//
// 	  if(confirmAll && confirmAll.length) {
//       let cookie = (this.getCookie() || ['', ''])[1];
//
//       for (let i = 0; i < this.inputs.length; i++) {
//         let ic = this.inputs[i];
//         ic.checked = cookie.indexOf(ic.value) >= 0;
//         saveOnChange && ic.addEventListener("change", function () {
//           me.saveCookies()
//         })
//       }
//     }
//
//     confirm.on('click',function() {
//       me.saveCookies();
//       me.close();
//       setTimeout(function() {
//         window.location.reload();
//       }, 250);
//     });
//
//     confirmAll.on('click',function() {
//       me.checkAll();
//       me.saveCookies();
//       me.close();
//       setTimeout(function() {
//         window.location.reload();
//       }, 250);
//     });
//
//     openButton.on('click',function(e) {
//       e.preventDefault();
//       me.open();
//     });
//
//   }
//
//   allowTagging() {
//     let cookie = this.getCookie();
//     let privacypage = $('body').hasClass('node-76');
//
//     if(cookie && typeof cookie === 'object' && cookie[0].indexOf('~stat~') > 0 && (!privacypage)) {
//       return true;
//     }
//
//     return false;
//   }
//
//   loadAnalytics() {
//
// 	  if(this.allowTagging()) {
//
//       var UA = 'UA-18779307-2';
//       if(window.location.host === 'robert-thomas.yellowspace.net') {
//         UA = 'UA-58394650-11';
//       }
//
//       (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
//         (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
//         m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
//       })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
//
//       ga('create', UA, 'auto');
//       ga('send', 'pageview');
//     }
//   }
//
//   loadTagManager() {
//
//     let x10 = $('body').hasClass('node-26');
//
//     if(this.allowTagging() && x10) {
//       (function (w, d, s, l, i) {
//         w[l] = w[l] || [];
//         w[l].push({
//           'gtm.start':
//             new Date().getTime(), event: 'gtm.js'
//         });
//         var f = d.getElementsByTagName(s)[0],
//           j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
//         j.async = true;
//         j.src =
//           'https://www.googletagmanager.com/gtag/js?id=' + i + dl;
//         f.parentNode.insertBefore(j, f);
//       })(window, document, 'script', 'dataLayer', 'AW-810384058X');
//
//       window.dataLayer = window.dataLayer || [];
//       function gtag(){dataLayer.push(arguments);}
//       gtag('js', new Date());
//       gtag('config', 'AW-810384058');
//
//       gtag('event', 'conversion', {'send_to': 'AW-810384058/eLkZCKr8nuoBELr1tYID'});
//     }
//   }
//
// }
