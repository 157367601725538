numeral.register('locale', 'de', {
	delimiters: {
		thousands: '.',
		decimal: ','
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't'
	},
	ordinal : function (number) {
		return number === 1 ? 'er' : 'e';
	},
	currency: {
		symbol: '€'
	}
});

numeral.locale('de');


(function($,w) {

	var dzSelector = 'form.rthcalculation';
	var defaultValues = true;
	
	var RTH_TOOL = function() {

		var me = this;
		var _this = this;

		// change for action and target
		// var rthcalculationform = Ext.get('rthcalculationform');
		// rthcalculationform.dom.action = '/local/tools/pdf/rth/pdf.php';
		// // CHANGE SUBMIT BUTTON
		// rthcalculationform.on('submit',function(event) {
		// 	_this.submitAndCheckRTHform(event,rthcalculationform);
		// },this);

		getLeftHandFields();
		getRightHandFields();
		addDefaults();
		//
		// var changeAbleFields = Ext.Element.select('.editable');
		// changeAbleFields.on('change',function(field, newValue, oldValue ) {
		// 	_this.calculateAll(false, field, false);
		// },this);

		var changeAbleFields = $('.editable');
		changeAbleFields.on('change',function() {
			var field = $(this);
			calculateAll(false, field, false);
		});

		changeAbleFields.on('keydown',function(e) {
			if(typeof console === 'object') { console.log('e',e,e.keyCode); }
			if(e.keyCode === 13) {
				var field = $( this );
				calculateAll( false, field, false );
			}
		});

		//
		// changeAbleFields.on('keydown',function(e, t, o ) {
		// 	if(e.keyCode == 13) _this.calculateAll(e, t, o );
		// },this);
		//
		// this.MaterialOptions = _this.materialField.select('option');
		// var filterFields = Ext.Element.select('.electrically_insulating');
		// filterFields.on('change',function(field, newValue, oldValue ) {
		// 	var v = newValue.value;
		// 	_this.filterMaterial(_this.materialField,v);
		// 	_this.calculateAll(false, field, false);
		// },this);
		// this.filterMaterial(_this.materialField,'y');
		//
		calculateAll(false,false,false);
		defaultValues = false;
	};

	var caseField,
		caseWidthField,
		caseHeightField,
		caseTotalField,
		field_rth_component_jc_kw,
		field_rth_component_pv,
		materialField,
		field_rth_tim_1inch,
		field_rth_tim_effective,
		field_rth_heatsink,
		field_rth_ambient_temperature,
		field_rth_heatsink_temperature,
		field_rth_chip_temperature,
		field_rth_tjmax;

	getLeftHandFields = function() {
		caseField = $('#field_case');
		caseWidthField = $('#field_case_width');
		caseHeightField = $('#field_case_height');
		caseTotalField = $('#field_case_total');

		field_rth_component_jc_kw = $('#field_rth_component_jc_kw');
		field_rth_component_pv = $('#field_rth_component_pv');


		materialField = $('#field_material');
		field_rth_tim_1inch = $('#field_rth_tim_1inch');
		field_rth_tim_effective = $('#field_rth_tim_effective');

		field_rth_heatsink = $('#field_rth_heatsink');
		field_rth_ambient_temperature = $('#field_rth_ambient_temperature');

		field_rth_heatsink_temperature = $('#field_rth_heatsink_temperature');
		field_rth_chip_temperature = $('#field_rth_chip_temperature');
		field_rth_tjmax = $('#field_rth_tjmax');
	};
	
	var field_rth_calc_jc_kw,
		field_rth_calc_tim_kw,
		field_rth_calc_hs,
		field_rth_calc_tj,
		field_rth_calc_tj_tjc,
		field_rth_calc_tjc,
		field_rth_calc_tjc_tfhs,
		field_rth_calc_tfhs,
		field_rth_calc_tfhs_ta,
		field_rth_calc_ta;
	
	getRightHandFields = function() {
		field_rth_calc_jc_kw = $('#field_rth_calc_jc_kw');
		field_rth_calc_tim_kw = $('#field_rth_calc_tim_kw');
		field_rth_calc_hs = $('#field_rth_calc_hs');

		field_rth_calc_tj = $('#field_rth_calc_tj');
		field_rth_calc_tj_tjc = $('#field_rth_calc_tj_tjc');
		field_rth_calc_tjc = $('#field_rth_calc_tjc');
		field_rth_calc_tjc_tfhs = $('#field_rth_calc_tjc_tfhs');
		field_rth_calc_tfhs = $('#field_rth_calc_tfhs');
		field_rth_calc_tfhs_ta = $('#field_rth_calc_tfhs_ta');
		field_rth_calc_ta = $('#field_rth_calc_ta');
	};

	var caseTotal = 0;
	var caseWidth = 0;
	var caseHeight = 0;
	var materialRaw = 0;
	var materialEffective = 0;

	addDefaults = function() {
		field_rth_component_jc_kw.val('0,3');
		field_rth_component_pv.val('25');
		field_rth_heatsink.val('0,35');
		field_rth_ambient_temperature.val('45');
		field_rth_tjmax.val('150');
	};


	calculateAll = function(e, t, o ) {
		calculateCase(e, t, o );
		calculateMaterial();
		updateRTHHeatCalculation();
		updateResult();
	};

	// this.case = caseVal;

	var caseWidth, caseHeight, caseTotal;

	calculateCase = function(e, t, o ) {
		var caseVal = caseField.val();
		// if(typeof console === 'object') { console.log('calculateCase.GETTING caseVal',caseVal); }

		var option = caseField.find('[value='+caseVal+']');
		if(option && option.length) {
			caseWidth = option.data('case-width');
			caseHeight = option.data('case-height');
		}

		updateCaseFields(e, t, o );
	};

	updateCaseFields = function(e, t, o ) {

		// case total: auto change through dropdown field_case OR changable by user
		// if target == field_case use calcaluation, else use field value if exits
		var manuell = false;
		var caseTotalFieldValue = this.numberizeFieldValue(caseTotalField.val(),'f');

		if(defaultValues) {
			manuell = false;
		}

		if(t
		     && t.type
		     && t.type === 'change'
		     && t.target
		     && t.target.id === 'field_case') {
			manuell = false;
		} else if(caseWidth && caseHeight) {
			caseWidth = parseInt(caseWidth);
			caseHeight = parseInt(caseHeight);
			caseTotal = caseWidth * caseHeight;
			if(caseTotalField > 0 && caseTotal !== caseTotalFieldValue) {
				manuell = true;
			}
		}

		// if(typeof console === 'object') { console.log('updateCaseFields manuell?, caseWidth',manuell,caseWidth); }


		if(caseWidth) {
			caseWidth = parseInt(caseWidth);
			if(manuell) {
				caseWidth = '-';
			}

			updateValue(caseWidthField,caseWidth);
		} else {
			updateValue(caseWidthField,'');
		}

		//
		if(caseHeight) {
			caseHeight = parseInt(caseHeight);
			if(manuell) {
				caseHeight = '-';
			}
			updateValue(caseHeightField,caseHeight);
		} else updateValue(caseHeightField,'');


		if(caseWidth && caseHeight) {

			caseTotal = caseWidth * caseHeight;
			if(manuell) {
				caseTotal = caseTotalField;
			}

			updateValue(caseTotalField,caseTotal);
		} else {
			updateValue(caseTotalField,'');
		}
	};

	var materialFormatted;
	var techproperty_sid = '2';

	calculateMaterial = function() {

		material = materialField.val();
		var option = materialField.find('[value='+material+']');

		if(option && option.length) {
			materialFormatted = option.data('material-formatted');
			materialRaw = option.data('material-raw');
			techproperty_sid = option.data('techproperty-sid');
		}

		updateMaterialFields();
	};

	updateMaterialFields = function() {

		var co = 645.16;

		if(materialRaw) {
			// this.techproperty_sid = 76 (Wärmeübergangswiderstand  (TO-3P)) --> wird auch inch2 umgerechnet: x*300/645,16
			// this.techproperty_sid = 2 (Wärmeübergangswiderstand (inch²))
			// if(typeof console === 'object') { console.log('materialRaw,techproperty_sid',materialRaw,techproperty_sid); }
			switch(techproperty_sid) {
				case'76':
					materialRaw = parseFloat(materialRaw)*360/co;
					break;
				case'2':
				default:
					materialRaw = parseFloat(materialRaw);
					break;
			}

			materialRaw = parseFloat(materialRaw);
			materialEffective = materialRaw*co/caseTotal;
			// if(typeof console === 'object') { console.log('materialRaw,materialEffective',materialRaw,materialEffective); }

			updateValue(field_rth_tim_1inch,formatFloat(materialRaw));
			updateValue(field_rth_tim_effective,formatFloat(materialEffective));
		} else {
			updateValue(field_rth_tim_1inch,'');
			updateValue(field_rth_tim_effective,'');
		}
	};

	var ta, pv, heatsink, tfhs, tjc, jc, tj;

	updateRTHHeatCalculation= function() {

		updateValue(field_rth_calc_jc_kw,field_rth_component_jc_kw.val());
		updateValue(field_rth_calc_tim_kw,field_rth_tim_effective.val());
		updateValue(field_rth_calc_hs,field_rth_heatsink.val());


		ta = numberizeFieldValue(field_rth_ambient_temperature.val(),'f');
		pv = numberizeFieldValue(field_rth_component_pv.val(),'f');
		heatsink = numberizeFieldValue(field_rth_heatsink.val(),'f');
		tfhs = (heatsink*pv) + ta;

		updateValue(field_rth_calc_ta,formatInt(ta));
		updateValue(field_rth_calc_tfhs,formatInt(tfhs));
		updateValue(field_rth_calc_tfhs_ta,formatInt(tfhs - ta));


		tjc = tfhs + (materialEffective * pv);
		updateValue(field_rth_calc_tjc,formatInt(tjc));
		updateValue(field_rth_calc_tjc_tfhs,formatInt(tjc - tfhs));

		jc = numberizeFieldValue(field_rth_component_jc_kw.val());
		tj = tjc + (jc*pv);
		updateValue(field_rth_calc_tj,formatInt(tj));
		updateValue(field_rth_calc_tj_tjc,formatInt(tj - tjc));

		updateValue(field_rth_heatsink_temperature,formatInt(tfhs));
		updateValue(field_rth_chip_temperature,formatInt(tj));
	};

	var tjmax, result_text, result_bg, result_class;

	updateResult = function() {

		tjmax = this.numberizeFieldValue(field_rth_tjmax.val());
		var r = tj / tjmax * 100;
		var t = '';

		var bg = '/local/media/images/rth-calculation/KU-KG_gelb-org.jpg';
		var result_class = 'result2';

		// interpret result
		if(r > 100) {
			result_class = 'result3';
			t += '<span class="result-text">'+$L('rthform_result_1')+'</span>';
			t += '<span class="result-icon result-x"><i class="icon-cancel"></i></span>';
			bg = '/local/media/images/rth-calculation/KU-KG_red-org.jpg';
		} else if(r > 80) {
			result_class = 'result3';
			t += '<span class="result-text">'+$L('rthform_result_2')+'</span>';
			t += '<span class="result-icon result-x"><i class="icon-cancel"></i></span>';
			bg = '/local/media/images/rth-calculation/KU-KG_red-org.jpg';
		} else if(r > 75) {
			t += '<span class="result-text">'+$L('rthform_result_3')+'</span>';
			t += '<span class="result-icon result-e-mark"><i class="icon-attention-alt"></i></span>';
		} else if(r > 10) {
			result_class = 'result1';
			t += '<span class="result-text">'+$L('rthform_result_4')+'</span>';
			t += '<span class="result-icon result-ok"><i class="icon-ok"></i></span>';
			bg = '/local/media/images/rth-calculation/KU-KG_green-org.jpg';
		} else {
			t += '<span class="result-text">'+$L('rthform_result_5')+'</span>';
			t += '<span class="result-icon result-e-mark"><i class="icon-attention-alt"></i></span>';
		}

		var rth_result = $('#rth_result');
		rth_result.html(t);
		var rth_bg_result = $('#rth_bg_result');
		rth_bg_result.css('backgroundImage','url('+bg+')');

		result_text = t;
		result_bg = bg;
		result_class = result_class;
	};


	$.fn.myExtension = function() {};

	$(document).ready(function() {
		var lD = $(dzSelector);
		if (lD && lD.get(0)) {
			RTH_TOOL();
		}
	});


	updateValue = function(f,v) {
		var oldValue = f.val();
		var newValue = v+'';
		f.val(v);

		if((!defaultValues) && oldValue !== newValue) {
			f.toggleClass("rth-highlight");

			var bb = setTimeout(function(){
				f.toggleClass("rth-highlight");
			},1000);
		}

	};

	numberizeFieldValue = function(v,t) {
		v = v.replace(',','.');

		switch(t) {
			case'n':
				v = parseInt(v);
				break;
			case'f':
			default:
				v = parseFloat(v);
				break;
		}
		return v;
	};

	formatFloat = function(v) {
		// if(typeof console === 'object') { console.log('formatFloat v',v,numeral(v).format('0.0,00')); }
		return numeral(v).format('0.0,00');
		// return v;
		// return Ext.util.Format.number(v,'0.00,0000/i');
	};

	formatInt = function(v) {
		// if(typeof console === 'object') { console.log('formatInt',v,numeral(v).format('0')); }
		return numeral(v).format('0');
		// return v;
		// return Ext.util.Format.number(v,'0');
	};


})(jQuery,window);


function RTH_TOOL() {


	this.init = function() {

		this.form = Ext.getBody().select('form.rthcalculation');
		if(this.form && this.form.getCount()>0) {
			this.initRTHForm();
		}
		var drheat = Ext.get('drheat');
		if(drheat) {
			var DRH = new DR_HEAT();
			DRH.init();
		}
	};

	this.submit = function() {
		var rthcalculationform = Ext.get('rthcalculationform');
		this.submitAndCheckRTHform(null,rthcalculationform);
		rthcalculationform.dom.submit();
	}

	this.submitAndCheckRTHform=function(event,form) {

		// get all disabled fields and write them into hidden field
		var disabledFields = Ext.DomQuery.jsSelect('input[disabled]'),
			i,
			f,
			d;

		for(i=0;i<disabledFields.length;i++) {
			d = disabledFields[i];
			f = Ext.get('_hidden_'+d.id);
			if(f) {
				f.dom.value = d.value;
			} else {
				f = document.createElement("input");
				f.type = 'hidden';
				f.class = 'hidden';
				f.id = '_hidden_'+d.id;
				f.name = d.name;
				f.value = d.value;
				form.dom.appendChild(f);
			}
		}

		// additional fields
		var addFields = [];
		addFields[0] = {n:'result_text'};
		addFields[1] = {n:'result_bg'};
		addFields[1] = {n:'result_class'};

		for(i=0;i<addFields.length;i++) {
			d = addFields[i];
			f = Ext.get('_hidden_'+d.n);
			if(f) {
				f.dom.value = this[d.n];
			} else {
				f = document.createElement("input");
				f.type = 'hidden';
				f.class = 'hidden';
				f.id = '_hidden_'+d.n;
				f.name = 'fields[root.editor]['+d.n+']';
				f.value = this[d.n];
				form.dom.appendChild(f);
			}
		}
	};

	this.initRTHForm=function() {
		var _this = this;

		// change for action and target
		var rthcalculationform = Ext.get('rthcalculationform');
		rthcalculationform.dom.action = '/local/tools/pdf/rth/pdf.php';
		// CHANGE SUBMIT BUTTON
		rthcalculationform.on('submit',function(event) {
			_this.submitAndCheckRTHform(event,rthcalculationform);
		},this);


		this.initTips();
		this.getLeftHandFields();
		this.getRightHandFields();
		this.defaultValues = true;
		this.addDefaults();

		var changeAbleFields = Ext.Element.select('.editable');
		changeAbleFields.on('change',function(field, newValue, oldValue ) {
			_this.calculateAll(false, field, false);
		},this);

		changeAbleFields.on('keydown',function(e, t, o ) {
			if(e.keyCode == 13) _this.calculateAll(e, t, o );
		},this);

		this.MaterialOptions = _this.materialField.select('option');
		var filterFields = Ext.Element.select('.electrically_insulating');
		filterFields.on('change',function(field, newValue, oldValue ) {
			var v = newValue.value;
			_this.filterMaterial(_this.materialField,v);
			_this.calculateAll(false, field, false);
		},this);
		this.filterMaterial(_this.materialField,'y');

		this.calculateAll(false,false,false);
		this.defaultValues = false;
	};

	// this.getLeftHandFields = function() {
	// 	this.caseField = Ext.get('field_case');
	// 	this.caseWidthField = Ext.get('field_case_width');
	// 	this.caseHeightField = Ext.get('field_case_height');
	// 	this.caseTotalField = Ext.get('field_case_total');
	//
	// 	this.field_rth_component_jc_kw = Ext.get('field_rth_component_jc_kw');
	// 	this.field_rth_component_pv = Ext.get('field_rth_component_pv');
	//
	//
	// 	this.materialField = Ext.get('field_material');
	// 	this.field_rth_tim_1inch = Ext.get('field_rth_tim_1inch');
	// 	this.field_rth_tim_effective = Ext.get('field_rth_tim_effective');
	//
	// 	this.field_rth_heatsink = Ext.get('field_rth_heatsink');
	// 	this.field_rth_ambient_temperature = Ext.get('field_rth_ambient_temperature');
	//
	// 	this.field_rth_heatsink_temperature = Ext.get('field_rth_heatsink_temperature');
	// 	this.field_rth_chip_temperature = Ext.get('field_rth_chip_temperature');
	// 	this.field_rth_tjmax = Ext.get('field_rth_tjmax');
	// };
	//
	// this.getRightHandFields = function() {
	// 	this.field_rth_calc_jc_kw = Ext.get('field_rth_calc_jc_kw');
	// 	this.field_rth_calc_tim_kw = Ext.get('field_rth_calc_tim_kw');
	// 	this.field_rth_calc_hs = Ext.get('field_rth_calc_hs');
	//
	// 	this.field_rth_calc_tj = Ext.get('field_rth_calc_tj');
	// 	this.field_rth_calc_tj_tjc = Ext.get('field_rth_calc_tj_tjc');
	// 	this.field_rth_calc_tjc = Ext.get('field_rth_calc_tjc');
	// 	this.field_rth_calc_tjc_tfhs = Ext.get('field_rth_calc_tjc_tfhs');
	// 	this.field_rth_calc_tfhs = Ext.get('field_rth_calc_tfhs');
	// 	this.field_rth_calc_tfhs_ta = Ext.get('field_rth_calc_tfhs_ta');
	// 	this.field_rth_calc_ta = Ext.get('field_rth_calc_ta');
	// };

	// this.addDefaults = function() {
	//
	// 	this.caseTotal = 0;
	// 	this.caseWidth = 0;
	// 	this.caseHeight = 0;
	// 	this.materialRaw = 0;
	// 	this.materialEffective = 0;
	//
	// 	//this.caseField.dom.value = '6';
	// 	this.field_rth_component_jc_kw.dom.value = '0,4';
	// 	this.field_rth_component_pv.dom.value = '10';
	// 	//this.materialField.dom.value = '659';
	// 	this.field_rth_heatsink.dom.value = '0,35';
	// 	this.field_rth_ambient_temperature.dom.value = '45';
	// 	this.field_rth_tjmax.dom.value = '150';
	//
	// };

	// this.calculateAll = function(e, t, o ) {
	// 	this.calculateCase(e, t, o );
	// 	this.calculateMaterial();
	// 	this.updateRTHHeatCalculation();
	// 	this.updateResult();
	// };

	// this.initTips = function() {
	// 	var _this = this;
	// 	var tips = Ext.Element.select('[data-tip]');
	// 	tips.each(function(el,c,idx) {
	// 		var tip = el.dom.getAttribute('data-tip');
	// 		new Ext.ToolTip({
	// 			target: el.id,
	// 			cls:'xlocal',
	// 			anchor: 'top',
	// 			autoHide: true,
	// 			dismissDelay:0,
	// 			anchorOffset: 10,
	// 			trackMouse: true,
	// 			maxWidth:500,
	// 			html: tip
	// 		});
	//
	// 	});
	// };

	// this.calculateCase = function(e, t, o ) {
	// 	this.case = this.caseField.dom.value;
	// 	var option = this.caseField.select('[value='+this.case+']');
	// 	if(option && option.getCount()>0) {
	// 		this.caseOption = Ext.get(option.first());
	// 		if(this.caseOption) {
	// 			this.caseWidth = this.caseOption.dom.getAttribute('data-case-width');
	// 			this.caseHeight = this.caseOption.dom.getAttribute('data-case-height');
	// 		}
	// 	}
	// 	this.updateCaseFields(e, t, o );
	// };

	// this.updateCaseFields = function(e, t, o ) {
	//
	// 	// case total: auto change through dropdown field_case OR changable by user
	// 	// if target == field_case use calcaluation, else use field value if exits
	// 	var manuell = false;
	// 	var caseTotalField = this.numberizeFieldValue(this.caseTotalField.dom.value,'f');
	//
	// 	if(this.defaultValues) {
	// 		manuell = false;
	// 	} if(t
	// 	     && t.type
	// 	     && t.type == 'change'
	// 	     && t.target
	// 	     && t.target.id == 'field_case') {
	// 		manuell = false;
	// 	} else if(this.caseWidth && this.caseHeight) {
	// 		this.caseWidth = parseInt(this.caseWidth);
	// 		this.caseHeight = parseInt(this.caseHeight);
	// 		this.caseTotal = this.caseWidth * this.caseHeight;
	// 		if(caseTotalField > 0 && this.caseTotal != caseTotalField) {
	// 			manuell = true;
	// 		}
	// 	}
	//
	// 	if(this.caseWidth) {
	// 		this.caseWidth = parseInt(this.caseWidth);
	// 		if(manuell) this.caseWidth = '-';
	// 		this.updateValue(this.caseWidthField,this.caseWidth);
	// 	} else this.updateValue(this.caseWidthField,'');
	//
	// 	if(this.caseHeight) {
	// 		this.caseHeight = parseInt(this.caseHeight);
	// 		if(manuell) this.caseHeight = '-';
	// 		this.updateValue(this.caseHeightField,this.caseHeight);
	// 	} else this.updateValue(this.caseHeightField,'');
	//
	//
	// 	if(this.caseWidth && this.caseHeight) {
	//
	// 		this.caseTotal = this.caseWidth * this.caseHeight;
	// 		if(manuell) {
	// 			this.caseTotal = caseTotalField;
	// 		}
	// 		this.updateValue(this.caseTotalField,this.caseTotal);
	// 	} else this.updateValue(this.caseTotalField,'');
	// };

	/**
	 *
	 * Filter material select by product category Elektrisch isolierend Ja/Nein
	 *
	 *
	 * @param f
	 * @param v
	 */
	this.filterMaterial = function(f,v) {

		var options = f.select('option'),
			val = 7, val2 = 40; // category "elektrisch isolierend"

		// remove all
		options.each(function(el) {
			el.remove();
		});
		// add all saved
		this.MaterialOptions.each(function(el) {
			f.appendChild(el);
		});
		options = f.select('option');

		options.each(function(el) {
			var show=false;
			var t = el.dom.getAttribute('data-category-sid');
			var t2 = el.dom.getAttribute('data-parent-category-sid');
			if(t) {
				switch(v) {
					case'y':
						if(t == val || t2 == val || t == val2 || t2 == val2) {
							show=true;
						} else {
							show=false;
						}
						break;
					default:
					case'n':
						if(t == val || t2 == val || t == val2 || t2 == val2) {
							show=false;
						} else {
							show=true;
						}
						break;
				}
			}

			if(!show) {
				el.remove();
			}
		});
	};
	//
	// this.calculateMaterial = function() {
	// 	this.material = this.materialField.dom.value;
	// 	var option = this.materialField.select('[value='+this.material+']');
	// 	if(option && option.getCount()>0) {
	// 		this.materialOption = Ext.get(option.first());
	// 		if(this.materialOption) {
	// 			this.materialFormatted = this.materialOption.dom.getAttribute('data-material-formatted');
	// 			this.materialRaw = this.materialOption.dom.getAttribute('data-material-raw');
	// 		}
	// 	}
	// 	this.updateMaterialFields();
	// };
	//
	// this.updateMaterialFields = function() {
	//
	// 	var co = 645.16;
	// 	this.techproperty_sid = '2';
	// 	if(this.materialOption) {
	// 		this.techproperty_sid = this.materialOption.dom.getAttribute('data-techproperty-sid');
	// 	}
	//
	// 	if(this.materialRaw) {
	// 		// this.techproperty_sid = 76 (Wärmeübergangswiderstand  (TO-3P)) --> wird auch inch2 umgerechnet: x*300/645,16
	// 		// this.techproperty_sid = 2 (Wärmeübergangswiderstand (inch²))
	//
	// 		switch(this.techproperty_sid) {
	// 			case'76':
	// 				this.materialRaw = parseFloat(this.materialRaw)*360/co;
	// 				break;
	// 			case'2':
	// 			default:
	// 				this.materialRaw = parseFloat(this.materialRaw);
	// 				break;
	// 		}
	//
	// 		this.materialRaw = parseFloat(this.materialRaw);
	// 		this.materialEffective = this.materialRaw*co/this.caseTotal;
	// 		this.updateValue(this.field_rth_tim_1inch,this.formatFloat(this.materialRaw));
	// 		this.updateValue(this.field_rth_tim_effective,this.formatFloat(this.materialEffective));
	// 	} else {
	// 		this.updateValue(this.field_rth_tim_1inch,'');
	// 		this.updateValue(this.field_rth_tim_effective,'');
	// 	}
	// };

	this.updateRTHHeatCalculation= function() {

		this.updateValue(this.field_rth_calc_jc_kw,this.field_rth_component_jc_kw.dom.value);
		this.updateValue(this.field_rth_calc_tim_kw,this.field_rth_tim_effective.dom.value);
		this.updateValue(this.field_rth_calc_hs,this.field_rth_heatsink.dom.value);


		this.ta = this.numberizeFieldValue(this.field_rth_ambient_temperature.dom.value,'f');
		this.pv = this.numberizeFieldValue(this.field_rth_component_pv.dom.value,'f');
		this.heatsink = this.numberizeFieldValue(this.field_rth_heatsink.dom.value,'f');
		this.tfhs = (this.heatsink*this.pv) + this.ta;

		this.updateValue(this.field_rth_calc_ta,this.formatInt(this.ta));
		this.updateValue(this.field_rth_calc_tfhs,this.formatInt(this.tfhs));
		this.updateValue(this.field_rth_calc_tfhs_ta,this.formatInt(this.tfhs - this.ta));


		this.tjc = this.tfhs + (this.materialEffective * this.pv);
		this.updateValue(this.field_rth_calc_tjc,this.formatInt(this.tjc));
		this.updateValue(this.field_rth_calc_tjc_tfhs,this.formatInt(this.tjc - this.tfhs));

		this.jc = this.numberizeFieldValue(this.field_rth_component_jc_kw.dom.value);
		this.tj = this.tjc + (this.jc*this.pv);
		this.updateValue(this.field_rth_calc_tj,this.formatInt(this.tj));
		this.updateValue(this.field_rth_calc_tj_tjc,this.formatInt(this.tj - this.tjc));

		this.updateValue(this.field_rth_heatsink_temperature,this.formatInt(this.tfhs));
		this.updateValue(this.field_rth_chip_temperature,this.formatInt(this.tj));

	};

	this.updateResult = function() {

		this.tjmax = this.numberizeFieldValue(this.field_rth_tjmax.dom.value);
		var r = this.tj / this.tjmax * 100;
		var t = '';
		var bg = '/local/media/images/rth-calculation/KU-KG_gelb-org.jpg';
		var result_class = 'result2';

		// interpret result
		if(r > 100) {
			result_class = 'result3';
			t += '<span class="result-text">'+$L('rthform_result_1')+'</span>';
			t += '<span class="result-icon result-x"><i class="icon-cancel"></i></span>';
			bg = '/local/media/images/rth-calculation/KU-KG_red-org.jpg';
		} else if(r > 80) {
			result_class = 'result3';
			t += '<span class="result-text">'+$L('rthform_result_2')+'</span>';
			t += '<span class="result-icon result-x"><i class="icon-cancel"></i></span>';
			bg = '/local/media/images/rth-calculation/KU-KG_red-org.jpg';
		} else if(r > 75) {
			t += '<span class="result-text">'+$L('rthform_result_3')+'</span>';
			t += '<span class="result-icon result-e-mark"><i class="icon-attention-alt"></i></span>';
		} else if(r > 10) {
			result_class = 'result1';
			t += '<span class="result-text">'+$L('rthform_result_4')+'</span>';
			t += '<span class="result-icon result-ok"><i class="icon-ok"></i></span>';
			bg = '/local/media/images/rth-calculation/KU-KG_green-org.jpg';
		} else {
			t += '<span class="result-text">'+$L('rthform_result_5')+'</span>';
			t += '<span class="result-icon result-e-mark"><i class="icon-attention-alt"></i></span>';
		}

		var rth_result = Ext.get('rth_result');
		rth_result.dom.innerHTML = t;
		var rth_bg_result = Ext.get('rth_bg_result');
		rth_bg_result.setStyle('backgroundImage','url('+bg+')');

		this.result_text = t;
		this.result_bg = bg;
		this.result_class = result_class;

	};


	this.updateValue = function(f,v) {
		var oldValue = f.dom.value;
		var newValue = v+'';
		f.dom.value = v;

		if((!this.defaultValues) && oldValue != newValue) {
			f.highlight('#ff0000',{ attr: 'color', duration: 2 });
		}

	};

	// this.numberizeFieldValue = function(v,t) {
	// 	v = v.replace(',','.');
	//
	// 	switch(t) {
	// 		case'n':
	// 			v = parseInt(v);
	// 			break;
	// 		case'f':
	// 		default:
	// 			v = parseFloat(v);
	// 			break;
	// 	}
	// 	return v;
	// };

	this.formatFloat = function(v) {
		return Ext.util.Format.number(v,'0.00,0000/i');
	};

	this.formatInt = function(v) {
		return Ext.util.Format.number(v,'0');
	};

}
var RTH = new RTH_TOOL();