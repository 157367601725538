
(function($) {

  var inputs, modal;

  var init = function () {
    $( document ).ready(function() {
      start();
    });
  }

  var start = function() {
    let toggle = $('.toggle');

    if(toggle && toggle.length) {
      toggle.on('click', function(e) {
        e.preventDefault();
        let $this = $(this);
        let ref = $this.data('toggle');
        let $ref = $('#'+ref);
        let isHidden = $ref.css('display');
        let pm = $this.find('.plus-minus');

        if(isHidden && isHidden === 'none') {
          $ref.fadeIn();
          if(pm && pm.length > 0) {
            pm.html('-');
          }
        } else {
          $ref.fadeOut(100, function() {
            if(pm && pm.length > 0) {
              pm.html('+');
            }
          });
        }
      });
    }
  }


  init();

})(jQuery);

class Toggle {

	constructor() {
    let me = this;

		$( document ).ready(function() {
      me.start();
		});
	}

  start() {
    let me = this;
	  let toggle = $('.toggle');

	  if(toggle && toggle.length) {
      toggle.on('click', function(e) {
        e.preventDefault();
        let $this = $(this);
        let ref = $this.data('toggle');
        let $ref = $('#'+ref);
        let isHidden = $ref.css('display');
        let pm = $this.find('.plus-minus');

        if(isHidden && isHidden === 'none') {
          $ref.fadeIn();
          if(pm && pm.length > 0) {
            pm.html('-');
          }
        } else {
          $ref.fadeOut(100, function() {
            if(pm && pm.length > 0) {
              pm.html('+');
            }
          });
        }
      });
    }
  }
}
