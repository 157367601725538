(function($) {

	var topSwiperImagesLoaded = false;
	var swipers;

	var init = function() {

		var me = this;
		$('body').addClass('js');

		initViewPortAnimations();
		initSwiper();

		$(window).on('resize',function(event){
			calculateSwiperHeight();
		});
	};

	var initViewPortAnimations = function () {
		$('.section--animate').viewportChecker({
			classToAdd: 'js--visible animated bounceIn', // Class to add to the elements when they are visible,
			classToAddForFullView: 'full-visible', // Class to add when an item is completely visible in the viewport
			classToRemove: 'js--hidden', // Class to remove before adding 'classToAdd' to the elements
			offset: '10%', // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
			repeat: false // Add the possibility to remove the class if the elements are not visible
		});

		$('.btn').addClass('.js--hidden').viewportChecker({
			classToAdd: 'js--visible animated bounceIn', // Class to add to the elements when they are visible,
			classToAddForFullView: 'full-visible', // Class to add when an item is completely visible in the viewport
			classToRemove: 'js--hidden', // Class to remove before adding 'classToAdd' to the elements
			offset: '10%', // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
			repeat: false // Add the possibility to remove the class if the elements are not visible
		});
	};

	/**
	 * Init all swipers
	 */
	var initSwiper = function() {

		if(checkTopSwiperBGIMagesLoaded()) {
			calculateSwiperHeight();

			swipers = $('.swiper-container');
			swipers.each(function (index) {
				if (!$(this).attr('id')) {
					$(this).attr('id', idGen.getId());
				}

				var id = $(this).attr('id');
				var options;

				if($(this).hasClass('swiper-animate')) {
					options = getSwiperTTLOptions($(this));
				} else {
					options = getSwiperOptions($(this));
				}

				$('#' + id).swiper(options);
			});
		} else {
			setTimeout(initSwiper,250);
		}
	};

	var getSwiperTTLOptions = function($swiper) {

		var ww = window.innerWidth;
		var id = $swiper.attr('id');
		var slides = $swiper.find('.swiper-slide');
		var slidesLength = slides.length;
		var titleSelector = '.tlt';
		var slideTitle = $swiper.find(titleSelector).addClass('js--hidden');
		var ttlInTimeout, ttlOutTimeout, ttlTimeout;
		var stop_loop = false;

		var ttl = function(swiper,$slide) {

			if(stop_loop) {
				return false;
			}

			var global_title = $swiper.find('.slide-title.global .tlt'),
				title;

			if (global_title && global_title.get(0)) {
				title = global_title;
				stop_loop = true;
			} else {
				title = $slide.find(titleSelector);
			}

			var inititialized = title.find('ul.texts');

			var ttlIn = function(t) {
				if(slidesLength === 1) {
					return false;
				}
				ttlInTimeout = setTimeout(function () {
					title.textillate('out');
				}, (1000+t));
			};

			var ttlOut = function(t) {
				ttlOutTimeout = setTimeout(function () {
					swiper.slideNext();
					title.addClass('js--hidden');
				}, (t+500));
			};

			if(!inititialized.get(0)) {

				var l = 0;
				var titles = [];
				var titlesIdx = 0;
				var lgTitle = 0;
				var charAnimationTime = 50;

				// get longest title to keep animations in sync
				title.each(function() {
					var tl = $.trim($(this).html()).length;
					var sync = false;
					var type = 'char';
					var inEffect = 'flipInX';
					var outEffect = 'flipOutX';

					if(tl > 50) {
						var sync = true;
						var type = 'word';
						tl = tl * 50;
					} else {
						tl = tl * charAnimationTime;
					}

					titles[titlesIdx] = {
						tl: tl,
						el: $(this),
						sync: sync,
						type: type,
						inEffect: inEffect,
						outEffect: outEffect
					};

					if(l<tl) {
						l = tl;
						lgTitle = titlesIdx;
					}
					titlesIdx++;
				});

				for(var ttls=0;ttls<titles.length;ttls++) {
					titles[ttls].el.textillate({
						autostart: false,
						in: {
							effect: titles[ttls].inEffect,
							idx: ttls,
							lgTitle:lgTitle,
							type: titles[ttls].type,
							sync: titles[ttls].sync,
							callback: function () {
								// if this animation is the longest title
								if(this.idx === this.lgTitle) {
									ttlIn((1000));
								}
							}
						},
						out: {
							effect: titles[ttls].outEffect,
							reverse: true,
							idx: ttls,
							lgTitle:lgTitle,
							type: titles[ttls].type,
							sync: titles[ttls].sync,
							callback: function () {
								if(this.idx === this.lgTitle && slidesLength > 0) {
									ttlOut((50));
								}
							}
						}
					});
				}
			}

			title.removeClass('js--hidden');
			title.textillate('in');
		};

		var test = false;

		var slideAnimation = function(swiper) {
			var slide = swiper.slides[swiper.activeIndex];
			var $slide = $(slide);
			clearTimeout(ttlTimeout);
			clearTimeout(ttlInTimeout);
			clearTimeout(ttlOutTimeout);
			ttlTimeout = setTimeout(function() {
				if(!test) {
					ttl(swiper,$slide);
				} else {
					$swiper.find(titleSelector).removeClass('js--hidden');
				}
			},250);
		};

		var options = {
			zoom: ($swiper.attr('data-zoom') == "y") ? true : false,
			loop: ($swiper.attr('data-loop') == "y" && slides.length > 1) ? true : false,
			paginationClickable: true,
			autoplayDisableOnInteraction: false,
			onInit: function(swiper) {
				if(typeof console === 'object') { console.log('slideAnimation?',slidesLength); }

				if(slidesLength === 1) {
					slideAnimation(swiper);
				}
			},
			onSlideChangeEnd: function(swiper) {
				slideAnimation(swiper);
			}
		};

		$swiper.find('.swiper-button-next').css('display', 'none');
		$swiper.find('.swiper-button-prev').css('display', 'none');

		return options;
	};
	
	var getSwiperOptions = function($swiper) {

		var ww = window.innerWidth;
		var id = $swiper.attr('id');
		var slides = $swiper.find('.swiper-slide');

		var options = {
			pagination: (($swiper.attr('data-pagination') == "y") && slides.length > 1 && ww > 768) ? '#' + id + ' .swiper-pagination' : null,
			autoplay: ($swiper.attr('data-autoplay')) ? (parseInt($swiper.attr('data-autoplay')) * 1000) : 0,
			zoom: ($swiper.attr('data-zoom') == "y") ? true : false,
			loop: ($swiper.attr('data-loop') == "y" && slides.length > 1) ? true : false,
			//loopAdditionalSlides: ($swiper.attr('data-loop') == "y") ? 1 : 0,
			paginationClickable: true
		};

		if ($swiper.attr('data-navigation') == "y" && slides.length > 1) {
			options['nextButton'] = '.swiper-button-next';
			options['prevButton'] = '.swiper-button-prev';
		} else {
			$swiper.find('.swiper-button-next').css('display', 'none');
			$swiper.find('.swiper-button-prev').css('display', 'none');
		}
		
		return options;
	};


	var calculateSwiperHeight = function(ww) {

		ww = ww || window.innerWidth;
		var swipers = $('.swiper-container');

		swipers.each(function( index ) {

			var swiper = $(this);
			var slides = swiper.find('.swiper-slide');
			var w = swiper.attr('data-max-width') || 0;
			var h = swiper.attr('data-max-height') || 0;
			var hi = swiper.attr('data-height-indicator') || 0;

			if(h) {

				var ch,
					chp;

				var wh = $(window).height();

				if (ww < w) {

					chp = ((100 * ww) / w);
					ch = Math.floor((chp / 100) * h);
					var canvasMargin = 30;

					if (hi) {
						hi = hi.replace(",", ".");
						hi = parseFloat(hi);

						if (hi > 0) {
							ch = ch * hi;
						}

						if((ch+mnH+lcH)>wh) {
							ch = wh - (mnH+lcH) + (cMT*-1);
						}
					}

					if (ch > 0) {
						swiper.find('.swiper-slide .bgimage').css({
							'min-height': (ch + canvasMargin) + 'px'
						});
					}
				}

				// max window height = 600
				var max_swiper_height = 667;
				if(wh > max_swiper_height) {
					wh = max_swiper_height;
				}

				if(h > wh) {
					// fix height to maximum page height
					var lcH = 0; //$('.logo.container').height();
					var mnH = $('#mainnav').height();
					var cMT = 0;// parseInt($('.content').css('marginTop')) || -55;
					var th = wh - (mnH+lcH) + (cMT*-1);

					if(th > 350) {
						h = th;
						swiper.find('.swiper-slide .bgimage').css({
							'min-height': h + 'px'
						});
					}
				}
			}
		});
	};


	var checkTopSwiperBGIMagesLoaded = function() {

		if(topSwiperImagesLoaded) {
			return topSwiperImagesLoaded;
		}

		var laszyBGS = $('.swiper-container .lazyBg');

		if((!laszyBGS) || (!laszyBGS.get(0))) {
			topSwiperImagesLoaded = true;
		} else {
			setTimeout(function() {
				checkTopSwiperBGIMagesLoaded();
			},1000);
		}

		return topSwiperImagesLoaded;
	};

	$(document).ready(function() {
		init();
	});




	var startHome = function() {

		var me = this;

		if(!$('body').hasClass('start-animation')) {
			$('.topaddress').css('visibility','visible');
			$('.logo-col').css('visibility','visible');
			return;
		}
		if(($(window).scrollTop())>35) {
			$('.topaddress').css('visibility', 'visible');
			$('.topaddress').addClass('animated fadeIn');
			$('.logo-col').css('visibility', 'visible');
		}

		if(this.topSwiperImagesLoaded) {

			setTimeout(function() {

				if(($(window).scrollTop())>35) {

					setTimeout(function() {
						me.loadTXTAnimation();
						me.backgroundImageGrowAndShrink();
					},2000);
				} else {
					var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
					$('.topaddress').css('visibility','visible');
					$('.topaddress').addClass('animated slideInDown').one(animationEnd, function() {

						$('.logo-col').css('visibility','visible');
						$('.logo-col').addClass('animated fadeIn');

						setTimeout(function() {
							me.loadTXTAnimation();
							me.backgroundImageGrowAndShrink();
						},1000);
					});
				}


			},500);

		} else {
			setTimeout(function() {
				me.startHome();
			},1000);
		}

	};

	var backgroundImageGrowAndShrink = function() {

		var me = this;
		var BGS = $('.top-slider .slide1 .bgimage');
		var time = 50000;

		if(this.topSwiperImagesLoaded) {

			if(!BGS.hasClass('background-image--transition')) {
				BGS.addClass('background-image--transition');
			}

			if(BGS.hasClass('background-image--grow')) {
				BGS.removeClass('background-image--grow');
				BGS.addClass('background-image--shrink');
				setTimeout(function() {
					me.backgroundImageGrowAndShrink();
				},time);
			} else {
				BGS.removeClass('background-image--shrink');
				BGS.addClass('background-image--grow');
				setTimeout(function() {
					me.backgroundImageGrowAndShrink();
				},time);
			}

		} else {
			setTimeout(function() {
				me.backgroundImageGrowAndShrink();
			},1000);
		}
	};

	var loadTXTAnimation = function() {

		var me = this;

		if(this.topSwiperImagesLoaded) {
			$('.content  .swiper-container .global-title .tlt ul').css('visibility','visible');
			$('.tlt').textillate({
				loop: true
				// type: 'word',
				// sync: true
			});
		} else {
			setTimeout(function() {
				me.loadTXTAnimation();
			},1000);
		}
	}





})(jQuery);



