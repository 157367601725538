(function($) {

	var selector = '.print-action',
		$selector = null;



	var init = function() {
		$selector = $(selector);
		$selector.on('click',function() {
			beforePrint();
		});
	};



	beforePrint = function() {

		var productSid = $selector.attr('data-product-sid');
		// var diagrams = LC.modalContents['diagram'];//['loaded'] = true;
		// if(!diagrams.loaded) {
		// 	loadDiagrams(productSid);
		// } else {
		// 	moveDiagrams();
		// }

		setTimeout(function() {
			doPrint();
		},1000);
	};


	doPrint = function() {
		window.print();
	};

	$(document).ready(function() {
		$selector = $(selector);

		if ($selector && $selector.length) {
			init();
		}
	});



	loadDiagrams = function(productSid) {
		checkDiagramsLoaded();
		LC.loadModal('diagram',{sid:productSid});
	};

	checkDiagramsLoaded = function() {
		var diagrams = LC.modalContents['diagram'];//['loaded'] = true;
		if(!diagrams.loaded) {
			setTimeout(function() {
				checkDiagramsLoaded();
			},1000);
		} else {
			moveDiagrams();
		}
	};


	moveDiagrams = function() {
		var diagrams = LC.modalContents['diagram'];
		if(!diagrams.moved) {
			var diagramsModal = $('#modal-diagram');
			diagramsModal.appendTo('.product-table');
			// doPrint();
		} else {
			LC.modalContents['diagram']['moved'] = true;
			// doPrint();
		}
	};

})(jQuery);


